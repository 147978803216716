.#{$rt-namespace}__toast {
    position: relative;
    min-height: var(--toastify-toast-min-height);
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1),
        0 2px 15px 0 rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    max-height: var(--toastify-toast-max-height);
    overflow: hidden;
    font-family: var(--toastify-font-family);
    cursor: pointer;
    direction: ltr;
    &--rtl {
        direction: rtl;
    }
    &-body {
        margin: auto 0;
        flex: 1 1 auto;
        padding: 6px;
        display: flex;
        align-items: center;
        & > div:last-child {
            flex: 1;
        }
    }
    &-icon {
        margin-inline-end: 10px;
        width: 20px;
        flex-shrink: 0;
        display: flex;
    }
}

.#{$rt-namespace}--animate {
    animation-fill-mode: both;
    /* DEFAULT VAL: 0.7s */
    animation-duration: 0.3s;
}

.#{$rt-namespace}--animate-icon {
    animation-fill-mode: both;
    animation-duration: 0.3s;
}

/*@media #{$rt-mobile} {
    .#{$rt-namespace}__toast {
        margin-bottom: 0;
        border-radius: 0;
    }
}*/
