body {
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

input[type='date'],
input[type='time'] {
    -webkit-appearance: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.aspect-ratio-1 {
    aspect-ratio: 1;
    max-width: 100%;
}

/*.swiper-wrapper {
    transition-timing-function: linear;
}*/

@supports not (aspect-ratio: 1) {
    .aspect-ratio-1 {
        position: relative;
    }

    .aspect-ratio-1::after {
        display: block;
        content: '';
        padding-bottom: 100%;
    }

    .aspect-ratio-1 img,
    .aspect-ratio-1 svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
